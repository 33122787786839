root, html {
  background-color: #282c34;
}

a {
  color: aliceblue;
}

.App {
  padding-top: 10px;
  text-align: center;
  color: aliceblue;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: aliceblue;
  padding-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-textfield {
  max-width: 500px;
  min-width: 300px;
}



.habit-fields {
  width: 60vw;
}

@media screen and (max-width: 768px) {
  .habit-fields {
    width: 90vw; 
  }
}

@media screen and (min-width: 1200px) {
  .habit-fields {
    width: 60vw;
  }
}



.habit-card {
  width: 60vw;
}

@media screen and (max-width: 768px) {
  .habit-card {
    width: 90vw; 
  }
}

@media screen and (min-width: 1200px) {
  .habit-card {
    width: 60vw;
  }
}

.MuiDialog-paper {
  background-color: #6B7E8E !important;
}

hr {
  height: 7px;
  border-radius: 4px;
}